@charset "UTF-8";
/*-------------------------------------------
loading
-------------------------------------------*/
.bl_loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgba(255, 255, 255, 0.7);
  animation: fadeOutAnime 0.3s linear both;
}
.bl_loading.-part {
  position: relative;
  width: 100%;
  max-height: 60px;
  margin-top: -60px;
}
.bl_loading.is_show {
  visibility: visible;
  animation: fadeInAnime 0.5s linear both;
}
.bl_loading .cv-spinner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bl_loading .spinner {
  width: 60px;
  height: 60px;
  border: 5px #ddd solid;
  border-top: 5px #000 solid;
  border-radius: 50%;
  animation: spinnerAnime 0.8s infinite linear;
}

@keyframes spinnerAnime {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fadeInAnime {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOutAnime {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
/*-------------------------------------------
list
-------------------------------------------*/
.bl_newsList:not(.-top) {
  margin-top: 0;
  min-height: 30vw;
}
@media only screen and (max-width: 767px) {
  .bl_newsList:not(.-top) {
    min-height: 100vw;
  }
  .bl_newsList[data-spwidth=max] {
    margin-left: -4%;
    margin-right: -4%;
  }
}
.bl_newsList .item {
  background-color: #fff;
}
.bl_newsList .item + .item {
  margin: 0;
  border-top: 1px solid #eee;
}
.bl_newsList .item:nth-of-type(2n+1) > a {
  background-color: #fafafa;
}
.bl_newsList .item > a {
  display: block;
  padding: 10px 12px;
  transition: all 0.3s cubic-bezier(0.17, 0.935, 0.305, 1);
}
.bl_newsList .item > a:hover {
  background-color: #e9f7fb;
  text-decoration: none;
}
.bl_newsList .item > a:hover .title {
  text-decoration: underline;
}
@media only screen and (max-width: 767px) {
  .bl_newsList .item > a {
    padding: 4% 6%;
  }
  .bl_newsList .item > a .title {
    font-size: 1.4rem;
  }
}
.bl_newsList .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.bl_newsList .tags time {
  display: inline-block;
  color: #333;
  white-space: nowrap;
}
.bl_newsList .tags span {
  display: inline-block;
  margin-left: 10px;
  padding: 3px 15px 1px;
  font-size: 1.2rem;
  line-height: 1.2;
}
.bl_newsList .tags .cat, .bl_newsList .tags .subcat {
  border-radius: 3px;
  background: #fff;
  white-space: nowrap;
  cursor: pointer;
}
.bl_newsList .tags .cat:hover, .bl_newsList .tags .subcat:hover {
  color: #0089ff;
  text-decoration: underline;
}
.bl_newsList .tags .cat {
  color: #0089ff;
  border: 1px solid #0089ff;
}
.bl_newsList .tags .subcat {
  color: #000;
  border: 1px solid #000;
}
@media only screen and (max-width: 767px) {
  .bl_newsList .tags {
    flex-wrap: wrap;
    margin-bottom: 8px;
  }
  .bl_newsList .tags time {
    width: 100%;
    font-size: 1.4rem;
    margin-bottom: 5px;
  }
  .bl_newsList .tags span {
    margin-left: 0;
    padding: 3px 8px 1px;
    font-size: 1.1rem;
  }
  .bl_newsList .tags span + span {
    margin-left: 5px;
  }
}

.bl_cardUnit.-newslist {
  margin-bottom: 0;
  min-height: 200px;
}
.bl_cardUnit.-newslist .cardImg > a {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 56.3%;
}
.bl_cardUnit.-newslist .cardImg img {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.bl_cardUnit.-newslist .title {
  line-height: 1.5;
  font-size: clamp(1.4rem, 1.3vw, 1.6rem);
}
.bl_cardUnit.-newslist .title > a {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.bl_cardUnit.-newslist .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.bl_cardUnit.-newslist .tags time {
  display: inline-block;
  color: #333;
  white-space: nowrap;
}
.bl_cardUnit.-newslist .tags span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px 5px 0;
  padding: 4px 15px 3px;
  font-size: 1.2rem;
  line-height: 1.2;
  white-space: nowrap;
  cursor: pointer;
}
.bl_cardUnit.-newslist .tags .cat, .bl_cardUnit.-newslist .tags .subcat {
  border-radius: 3px;
  background: #fff;
  white-space: nowrap;
  cursor: pointer;
}
.bl_cardUnit.-newslist .tags .cat {
  color: #0089ff;
  border: 1px solid #0089ff;
}
.bl_cardUnit.-newslist .tags .cat:hover {
  color: #fff;
  background: #0089ff;
}
.bl_cardUnit.-newslist .tags .subcat {
  color: #000;
  border: 1px solid #000;
}
.bl_cardUnit.-newslist .tags .subcat:hover {
  color: #fff;
  background: #333;
}
@media only screen and (max-width: 767px) {
  .bl_cardUnit.-newslist .tags {
    flex-wrap: wrap;
  }
  .bl_cardUnit.-newslist .tags time {
    width: 100%;
    font-size: 1.4rem;
    margin-bottom: 5px;
  }
}
.bl_cardUnit.-newslist.-noTag .bl_card .tags {
  display: none;
}

/*-------------------------------------------
filter
-------------------------------------------*/
.bl_filterYear {
  display: flex;
  margin-bottom: 40px;
}
@media only screen and (max-width: 767px) {
  .bl_filterYear {
    display: block;
    margin-bottom: 6.5%;
  }
}
.bl_filterYear ul {
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  position: relative;
  padding-left: 5em;
}
@media only screen and (max-width: 767px) {
  .bl_filterYear ul {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
    padding-left: calc(1.5em + 4%);
  }
}
.bl_filterYear ul li {
  margin: 0 10px;
}
@media only screen and (max-width: 767px) {
  .bl_filterYear ul li {
    margin: 0;
    text-align: center;
  }
}
.bl_filterYear ul li:first-child {
  position: absolute;
  left: 0;
  top: 0;
}
@media only screen and (max-width: 767px) {
  .bl_filterYear ul li:first-child {
    margin: 0;
    text-align: left;
  }
}
.bl_filterYear label {
  font-weight: 600;
  font-size: 2rem;
  font-size: fontClamp(10, 20);
  line-height: 1;
  color: #004098;
  border: transparent 1px solid;
  padding: 7px 8px 6px;
  transition: all 0.3s;
  text-align: center;
  transition: all 0.3s cubic-bezier(0.17, 0.935, 0.305, 1);
}
.bl_filterYear label:hover, .bl_filterYear label:focus {
  text-decoration: none;
  border-color: #004098;
}
@media only screen and (max-width: 767px) {
  .bl_filterYear label {
    font-size: 1.4rem;
  }
}
.bl_filterYear input {
  display: none;
}
.bl_filterYear input:checked + label {
  color: #fff;
  background: #004098;
  border-color: #004098;
}

@media print, screen and (min-width: 768px) {
  .bl_filterWrap {
    margin-top: -30px;
  }
}
.bl_filterWrap .el_detailBtn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  opacity: 0.75;
}
@media only screen and (max-width: 767px) {
  .bl_filterWrap .el_detailBtn {
    margin-bottom: 10px;
  }
}
.bl_filterWrap .el_detailBtn button {
  position: relative;
  font-size: 1.6rem;
  font-weight: 600;
  color: #000;
  background: #eaeef4;
  border: none;
  padding: 15px 50px 15px 30px;
  display: flex;
  align-items: center;
  transition: all 0.3s cubic-bezier(0.17, 0.935, 0.305, 1);
}
.bl_filterWrap .el_detailBtn button:hover {
  background: #eee;
}
.bl_filterWrap .el_detailBtn button::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(../images/icon_equalizer.svg) 0 0 no-repeat;
  background-size: contain;
  margin-right: 10px;
}
.bl_filterWrap .el_detailBtn button span {
  display: block;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  right: 20px;
}
.bl_filterWrap .el_detailBtn button span::before, .bl_filterWrap .el_detailBtn button span::after {
  content: "";
  display: block;
  width: 16px;
  height: 2px;
  background-color: #000;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.bl_filterWrap .el_detailBtn button span::after {
  transform: translateY(-50%) rotate(90deg);
  transition: 0.5s;
}
@media only screen and (max-width: 767px) {
  .bl_filterWrap .el_detailBtn button {
    font-size: 1.4rem;
  }
}
.bl_filterWrap .el_detailBtn button.is_open span::after {
  transform: rotate(0);
  margin-top: -1px;
}

.bl_filterBox {
  margin-bottom: 40px;
  background: #eaeef4;
  display: none;
}
.bl_filterBox.is_open {
  display: block;
}
@media only screen and (max-width: 767px) {
  .bl_filterBox {
    margin-right: -4%;
    margin-left: -4%;
  }
}
.bl_filterBox .bl_filterBox_inner {
  padding: 3.3%;
}
@media only screen and (max-width: 767px) {
  .bl_filterBox .bl_filterBox_inner {
    padding: 8% 5%;
  }
}
.bl_filterBox .bl_filterKeyword {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
@media only screen and (max-width: 767px) {
  .bl_filterBox .bl_filterKeyword {
    margin-bottom: 6.5%;
  }
}
.bl_filterBox .bl_filterKeyword .el_keyword {
  width: 100%;
  height: 50px;
  max-width: 300px;
}
@media only screen and (max-width: 767px) {
  .bl_filterBox .bl_filterKeyword .el_keyword {
    max-width: 100%;
  }
}
.bl_filterBox .bl_filterKeyword .el_keyword input[type=text] {
  width: 100%;
  height: 50px;
  padding: 5px 10px;
  line-height: 50px;
  background: #fff;
  -webkit-appearance: none;
  appearance: none;
}
.bl_filterBox .bl_filterKeyword .el_keyword button {
  width: 50px;
  height: 50px;
  background: #fff;
  border: none;
  transition: all 0.5s;
}
.bl_filterBox .bl_filterKeyword .el_keyword button:hover {
  text-decoration: none;
  opacity: 0.75;
}
.bl_filterBox .bl_filterKeyword .el_keyword button::after {
  content: "\e906";
  font-size: 1.6rem;
  color: #000;
}
.bl_filterBox .bl_filterKeyword .el_keyword button:hover {
  background-color: #000;
  border-color: #000;
}
.bl_filterBox .bl_filterKeyword .el_keyword button:hover::after {
  color: #fff;
}
.bl_filterBox .bl_filterList [role=tablist] {
  margin-bottom: 2em;
}
.bl_filterBox .bl_filterList .listWrap {
  position: relative;
  display: grid;
  margin-top: 2em;
}
@media print, screen and (min-width: 768px) {
  .bl_filterBox .bl_filterList .listWrap {
    padding-left: 9em;
  }
  .bl_filterBox .bl_filterList .listWrap.-col2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .bl_filterBox .bl_filterList .listWrap.-col3 {
    grid-template-columns: repeat(3, 1fr);
  }
  .bl_filterBox .bl_filterList .listWrap.-col4 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media only screen and (max-width: 767px) {
  .bl_filterBox .bl_filterList .listWrap {
    padding-left: 0;
    grid-template-columns: minmax(100px, 1fr) 1fr;
  }
}
.bl_filterBox .bl_filterList .listWrap dt {
  font-weight: 600;
  position: absolute;
  left: 0;
  top: 0;
  line-height: 1;
  white-space: nowrap;
}
@media only screen and (max-width: 767px) {
  .bl_filterBox .bl_filterList .listWrap dt {
    font-size: 1.4rem;
  }
}
.bl_filterBox .bl_filterList .listWrap dd {
  margin: 0 20px 10px 0;
  line-height: 1.5;
  font-weight: 600;
}
@media only screen and (max-width: 767px) {
  .bl_filterBox .bl_filterList .listWrap dd:nth-of-type(2n) {
    margin-right: 0;
  }
}
.bl_filterBox .bl_filterList .bl_grid {
  padding: 0 20px;
}
.bl_filterBox .bl_filterList .bl_grid.-col2, .bl_filterBox .bl_filterList .bl_grid.-col3 {
  margin-bottom: -10px;
}
.bl_filterBox .bl_filterList .bl_grid .grid {
  margin-bottom: 10px;
}
@media print, screen and (min-width: 768px) {
  .bl_filterBox .bl_filterList .bl_grid .grid label {
    font-size: 100%;
  }
  .bl_filterBox .bl_filterList .bl_grid.-fluct .grid + .grid {
    margin-left: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .bl_filterBox .bl_filterList .bl_grid {
    padding: 0;
  }
}
.bl_filterBox .bl_filterBox_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.bl_filterBox .bl_filterBox_btn button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  appearance: none;
  transition: all 0.3s cubic-bezier(0.17, 0.935, 0.305, 1);
  padding: 14px 30px;
  color: #fff;
  border: 1px solid #004098;
  font-size: 1.4rem;
}
.bl_filterBox .bl_filterBox_btn button.reset {
  color: #004098;
  background: #fff;
  border-color: #fff;
}
.bl_filterBox .bl_filterBox_btn button.reset:hover {
  border-color: #004098;
}
.bl_filterBox .bl_filterBox_btn button.submit {
  background: #004098;
  margin-left: 1.6666666667vw;
}
.bl_filterBox .bl_filterBox_btn button.submit:hover {
  color: #004098;
  background: #fff;
  border-color: #004098;
}
@media only screen and (max-width: 767px) {
  .bl_filterBox .bl_filterBox_btn button {
    padding: 10px 20px;
  }
}
.bl_searchNoResult {
  text-align: center;
  font-weight: 600;
}

.bl_moreList {
  text-align: center;
  margin-top: 30px;
}
.bl_moreList .btn {
  transition: all 0.3s cubic-bezier(0.17, 0.935, 0.305, 1);
  padding: 10px 30px;
  color: #fff;
  border: 1px solid #004098;
  background: #004098;
  display: none;
  text-decoration: none;
}
.bl_moreList .btn.is_show {
  display: inline-block;
}
.bl_moreList .btn:hover {
  color: #004098;
  background: #fff;
  border-color: #004098;
  text-decoration: none;
}

.bl_backFilter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.bl_backFilter button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  appearance: none;
  position: relative;
  font-size: 1.6rem;
  font-weight: 600;
  color: #000;
  padding-right: 22px;
  margin-left: 1.5em;
  display: flex;
  align-items: center;
  text-decoration: underline;
}
.bl_backFilter button::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  margin: auto;
  width: 8px;
  height: 8px;
  border-top: #000 1px solid;
  border-right: #000 1px solid;
  transform: translateY(-50%) rotate(-45deg);
  border-width: 2px;
  top: 60%;
  right: 0;
}
.bl_backFilter button:hover {
  text-decoration: none;
}

/*-------------------------------------------
article
-------------------------------------------*/
.bl_article {
  opacity: 0;
  margin: 0 auto 60px;
  max-width: 1200px;
  font-size: 1.6rem;
}
@media only screen and (max-width: 767px) {
  .bl_article {
    min-height: 50vh;
    padding: 0 4%;
  }
  .bl_article + .linkBtn {
    width: 60vw;
  }
}
@media print, screen and (min-width: 768px) {
  .bl_article {
    min-height: 90vh;
  }
  .bl_article + .linkBtn {
    min-width: 400px;
  }
}
.bl_article.is_loaded {
  animation: fadeInAnime 0.5s linear forwards;
  opacity: 1;
}
.bl_article[data-type=latest] {
  position: relative;
  max-height: 200px;
  overflow: hidden;
}
.bl_article[data-type=latest]::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(transparent 0%, #ffffff 100%);
}
.bl_article .bl_headingWrap.-news {
  margin-bottom: 20px;
  flex-direction: column;
}
.bl_article .bl_headingWrap.-news .bl_headingLv1 {
  padding-bottom: 0;
}
.bl_article .bl_headingWrap.-news .itemRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 15px;
}
@media print, screen and (min-width: 768px) {
  .bl_article .bl_headingWrap.-news .bl_headingLv1 {
    font-size: 2.4rem;
  }
}
.bl_article .date {
  text-align: right;
  margin-top: 2.5vw;
}
@media print, screen and (min-width: 768px) and (max-width: 959px) {
  .bl_article .date {
    margin-top: 5vw;
  }
}
@media only screen and (max-width: 767px) {
  .bl_article .date {
    font-size: 1.4rem;
    margin-top: 16vw;
  }
}
.bl_article .title {
  font-size: clamp(2rem, 1.8vw, 2.8rem);
  font-weight: 600;
  margin: 2.5vw 0 1.6666666667vw;
  line-height: 1.4;
  text-align: center;
}
@media print, screen and (min-width: 768px) and (max-width: 959px) {
  .bl_article .title {
    margin-top: 5vw;
  }
}
@media only screen and (max-width: 767px) {
  .bl_article .title {
    font-size: 2rem;
    margin: 8vw 0 4vw;
  }
}
.bl_article .bl_headingLv1 {
  padding-bottom: 0;
}
@media print, screen and (min-width: 768px) {
  .bl_article .bl_headingLv1 {
    font-size: 2.4rem;
  }
}
.bl_article .tags {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.bl_article .tags li {
  font-size: 1.2rem;
  line-height: 1.2;
}
.bl_article .tags li + li {
  margin-left: 10px;
}
.bl_article .tags .cat, .bl_article .tags .subcat {
  background: #fff;
  padding: 5px 15px 3px;
  border-radius: 3px;
}
.bl_article .tags .cat {
  color: #0089ff;
  border: 1px solid #0089ff;
}
.bl_article .tags .subcat {
  color: #000;
  border: 1px solid #000;
}
@media only screen and (max-width: 767px) {
  .bl_article .tags li {
    margin-top: 1.3333333333vw;
  }
  .bl_article .tags li + li {
    margin-left: 10px;
  }
  .bl_article .tags .cat, .bl_article .tags .subcat {
    padding: 3px 10px 2px;
    border-radius: 3px;
  }
}
.bl_article .meta {
  text-align: right;
}
.bl_article .meta span {
  display: block;
}
@media only screen and (max-width: 767px) {
  .bl_article .meta {
    font-size: 1.4rem;
  }
}
.bl_article .post {
  margin-top: 4.1666666667vw;
}
@media only screen and (max-width: 767px) {
  .bl_article .post {
    font-size: 1.4rem;
    margin-top: 8vw;
  }
}
.bl_article .post h2 {
  font-size: 2.4rem;
  font-weight: 600;
  margin: 4.75vw 0 1.6666666667vw;
  line-height: 1.4;
}
.bl_headingLv1 + .bl_article .post h2 {
  margin-top: 0;
}
.bl_article .post h2 + h3 {
  margin-top: 0;
}
.bl_article .post h2 + p {
  margin-top: 0;
}
@media print, screen and (min-width: 768px) and (max-width: 959px) {
  .bl_article .post h2 {
    margin-top: 8vw;
  }
}
@media only screen and (max-width: 767px) {
  .bl_article .post h2 {
    font-size: 2rem;
    margin: 12.5333333333vw 0 4.5333333333vw;
  }
  .bl_headingLv1 + .bl_article .post h2 {
    margin-top: 0;
  }
}
.bl_article .post h3 {
  font-size: 1.8rem;
  font-weight: 600;
  margin: 4.75vw 0 0.8333333333vw;
  line-height: 1.6;
}
.bl_article .post h3 + p {
  margin-top: 0;
}
.bl_article .post h3 + table {
  margin-top: 1.5em;
}
@media print, screen and (min-width: 768px) and (max-width: 959px) {
  .bl_article .post h3 {
    margin: 5.8666666667vw 0 1.3333333333vw;
  }
}
@media only screen and (max-width: 767px) {
  .bl_article .post h3 {
    margin: 9.8666666667vw 0 2.6666666667vw;
  }
}
.bl_article .post h4 {
  font-size: 1.6rem;
  font-weight: 600;
  margin: 2.5vw 0 0.8333333333vw;
  line-height: 1.6;
}
.bl_article .post h4 + p {
  margin-top: 0;
}
@media print, screen and (min-width: 768px) and (max-width: 959px) {
  .bl_article .post h4 {
    margin: 2.6666666667vw 0 1.3333333333vw;
  }
}
@media only screen and (max-width: 767px) {
  .bl_article .post h4 {
    margin: 5.3333333333vw 0 2.6666666667vw;
  }
}
.bl_article .post p + p {
  margin-top: 1.5em;
}
.bl_article .post p a {
  text-decoration: underline;
}
.bl_article .post p a:hover {
  text-decoration: none;
}
.bl_article .post hr {
  margin: 5vw 0;
}
@media print, screen and (min-width: 768px) and (max-width: 959px) {
  .bl_article .post hr {
    margin: 10.6666666667vw 0;
  }
}
@media only screen and (max-width: 767px) {
  .bl_article .post hr {
    margin: 16vw 0;
  }
}
.bl_article .post table {
  border-top: #ccc 1px solid;
  border-left: #ccc 1px solid;
  width: 100%;
  border-collapse: collapse;
  font-size: 1.4rem;
  margin: 5vw 0;
}
.bl_article .post table caption {
  margin: 0;
  padding: 10px 0;
  border: #ccc 1px solid;
  border-bottom: none;
  background: #eaeaea;
  text-align: center;
}
.bl_article .post table th, .bl_article .post table td {
  padding: 18px;
  border-right: #ccc 1px solid;
  border-bottom: #ccc 1px solid;
  font-weight: normal;
  background: #fff;
}
.bl_article .post table th {
  background: #f4f4f4;
  text-align: left;
}
.bl_article .post table p {
  margin: 0;
}
@media print, screen and (min-width: 768px) and (max-width: 959px) {
  .bl_article .post table {
    margin: 10.6666666667vw 0;
  }
}
@media only screen and (max-width: 767px) {
  .bl_article .post table {
    margin: 16vw 0;
  }
}
@media print, screen and (min-width: 768px) {
  .bl_article .post table th, .bl_article .post table td {
    padding: 10px;
  }
}
@media only screen and (max-width: 959px) {
  .bl_article .post table th, .bl_article .post table td {
    padding: 8px;
  }
}
.bl_article .post ol, .bl_article .post ul {
  padding-left: 2em;
  margin: 1.5em 0;
}
.bl_article .post ul > li {
  list-style-type: disc;
}
.bl_article .post ul > li + li {
  margin-top: 0.5em;
}
.bl_article .post ol > li {
  list-style-position: outside;
  list-style-type: decimal;
  margin-left: 0;
}
.bl_article .post ol > li + li {
  margin-top: 0.5em;
}
.bl_article .post li p {
  margin: 0;
}
.bl_article .post em {
  font-style: italic;
}
.bl_article .post ._superscript {
  vertical-align: super;
  font-size: 0.7em;
}
.bl_article .post ._subscript {
  vertical-align: sub;
  font-size: 0.7em;
}
.bl_article .post .bl_imgWrap {
  margin: 2.5vw auto;
}
.bl_article .post .bl_imgWrap figcaption {
  font-size: 1.4rem;
  margin-top: clamp(15px, 1.6666666667vw, 20px);
}
@media only screen and (max-width: 959px) {
  .bl_article .post .bl_imgWrap {
    margin: 8vw auto;
  }
  .bl_article .post .bl_imgWrap figcaption {
    font-size: 1.2rem;
  }
}
.bl_article .post .bl_media {
  margin: 5vw 0;
}
@media print, screen and (min-width: 768px) and (max-width: 959px) {
  .bl_article .post .bl_media {
    margin: 10.6666666667vw 0;
  }
}
@media only screen and (max-width: 767px) {
  .bl_article .post .bl_media {
    margin: 16vw 0;
  }
}
.bl_article .post .bl_media .mediaImg figcaption {
  text-align: center;
  font-size: 1.4rem;
  margin-top: clamp(15px, 1.6666666667vw, 20px);
}
.bl_article .post .bl_media .mediaBody h2:first-child,
.bl_article .post .bl_media .mediaBody h3:first-child {
  margin-top: 0;
}
@media print, screen and (min-width: 768px) {
  .bl_article .post .bl_media .mediaImg {
    max-width: 48.35%;
  }
}
@media only screen and (max-width: 959px) {
  .bl_article .post .bl_media .mediaImg figcaption {
    font-size: 1.2rem;
  }
}
.bl_article .post .bl_cardUnit {
  margin: 5vw 0;
}
@media print, screen and (min-width: 768px) and (max-width: 959px) {
  .bl_article .post .bl_cardUnit {
    margin: 10.6666666667vw 0;
  }
}
@media only screen and (max-width: 767px) {
  .bl_article .post .bl_cardUnit {
    margin: 16vw 0;
  }
}
.bl_article .post .bl_cardUnit + .bl_cardUnit {
  margin-top: 0;
}
.bl_article .post .bl_cardUnit .bl_card .cardImg {
  text-align: center;
}
.bl_article .post .bl_cardUnit .bl_card .cardImg img {
  max-width: 100%;
  width: auto;
}
.bl_article .post .bl_cardUnit .bl_card .cardImg figcaption {
  text-align: center;
  font-size: 1.4rem;
  margin-top: clamp(15px, 1.6666666667vw, 20px);
}
@media print, screen and (min-width: 768px) {
  .bl_article .post .bl_cardUnit .bl_card {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 959px) {
  .bl_article .post .bl_cardUnit {
    margin: 0;
  }
  .bl_article .post .bl_cardUnit .cardImg figcaption {
    font-size: 1.2rem;
  }
}
.bl_article .post .el_btn {
  display: inline-block;
  padding: clamp(9px, 1vw, 12px) clamp(22.5px, 2.5vw, 30px);
  border: #5d5d5d 1px solid;
  color: #333;
  text-align: center;
  transition: all 0.5s;
}
.bl_article .post .el_btn:hover {
  text-decoration: none;
  opacity: 0.75;
}
.bl_article .post .el_btn:hover, .bl_article .post .el_btn:focus {
  background: #000;
  color: #fff;
}
.bl_article .post .el_btn.-stand {
  border-color: #004098;
  background: #004098;
  color: #fff;
  transition: all 0.5s cubic-bezier(0.17, 0.935, 0.305, 1);
}
.bl_article .post .el_btn.-stand:hover, .bl_article .post .el_btn.-stand:focus {
  background: #fff;
  color: #004098;
  opacity: 1;
}
.bl_article .post .el_btn.-stand:hover.-blank::after, .bl_article .post .el_btn.-stand:focus.-blank::after {
  color: #004098;
}
.bl_article .post .el_btn.-stand.-blank::after {
  color: #fff;
}
.bl_article .post .el_btn.-white {
  border-color: #fff;
  background: #fff;
  color: #004098;
  transition: all 0.5s cubic-bezier(0.17, 0.935, 0.305, 1);
}
.bl_article .post .el_btn.-white:hover.-blank::after, .bl_article .post .el_btn.-white:focus.-blank::after {
  color: #004098;
}
.bl_article .post .el_btn.-white.-blank::after {
  color: #004098;
}
.bl_article .post .el_btn.-wide {
  min-width: 400px;
}
@media only screen and (max-width: 767px) {
  .bl_article .post .el_btn.-wide {
    min-width: auto;
    width: auto;
  }
}
.bl_article .post .el_btn.-blank::after {
  font-family: "svgicon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e907";
  font-size: 1.2rem;
  margin-left: 8px;
  color: #5d5d5d;
  transition: all 0.3s;
}
.bl_article .post .el_btn.-blank:hover::after, .bl_article .post .el_btn.-blank:focus::after {
  color: #fff;
}
.bl_article .post .bl_btnWrap {
  display: flex;
  justify-content: center;
  margin: 60px 0 0;
}
.bl_article .post .bl_btnWrap.-left {
  justify-content: flex-start;
}
@media print, screen and (min-width: 768px) {
  .bl_article .post .bl_btnWrap .el_btn + .el_btn {
    margin-left: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .bl_article .post .bl_btnWrap {
    margin: 40px 0 0;
    flex-direction: column;
    align-items: center;
  }
  .bl_article .post .bl_btnWrap .el_btn + .el_btn {
    margin-top: 20px;
  }
}
@media print, screen and (min-width: 768px) {
  .bl_article .post .bl_table.-half {
    width: auto;
  }
}
.bl_article .post .bl_table.-noBorder {
  font-size: 1.6rem;
}
.bl_article .post .bl_table.-noBorder th, .bl_article .post .bl_table.-noBorder td {
  padding: 3px 0;
}
.bl_article .post > *:first-child {
  margin-top: 0;
}
.bl_article .post ._labelPDF::after,
.bl_article .post ._labelEXL::after,
.bl_article .post ._labelZIP::after,
.bl_article .post ._labelVIDEO::after,
.bl_article .post ._labelAUDIO::after {
  display: inline-block;
  padding: 1px 8px;
  color: #fff;
  font-size: 11px;
  line-height: 1.4;
  vertical-align: middle;
  margin: -2px 0 0 7px;
}
.bl_article .post ._labelPDF::after {
  content: "PDF";
  background-color: #c90000;
}
.bl_article .post ._labelEXL::after {
  content: "EXCEL";
  background-color: #19792b;
}
.bl_article .post ._labelZIP::after {
  content: "ZIP";
  background-color: #666;
}
.bl_article .post ._labelVIDEO::after {
  content: "VIDEO";
  background-color: #118cdd;
}
.bl_article .post ._labelAUDIO::after {
  content: "AUDIO";
  background-color: #118cdd;
}

.bl_box .bl_article[data-type=latest]::after {
  background: linear-gradient(transparent 0%, #eaeef4 100%);
}

/*-------------------------------------------
importArticle
-------------------------------------------*/
@media only screen and (max-width: 767px) {
  .bl_importArticle {
    font-size: 1.4rem;
  }
}
.bl_importArticle .bl_headingWrap.-news {
  margin-bottom: 20px;
  flex-direction: column;
}
.bl_importArticle .bl_headingWrap.-news .bl_headingLv1 {
  padding-bottom: 0;
}
.bl_importArticle .bl_headingWrap.-news .itemRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 15px;
}
@media print, screen and (min-width: 768px) {
  .bl_importArticle .bl_headingWrap.-news .bl_headingLv1 {
    font-size: 2.4rem;
  }
}
.bl_importArticle .bl_newsDetail .bl_card .cardBody.bclo-textWrap {
  padding-top: 0 !important;
  margin: 20px 0 0;
}
.bl_importArticle .bl_newsDetail .bl_card .cardBody.bclo-textWrap .bclo-textWrap-title {
  margin-bottom: 10px;
}
.bl_importArticle .bl_newsDetail .bl_card .cardBody.bclo-textWrap .bclo-link {
  margin-top: 20px;
}
.bl_importArticle .bl_newsDetail .bl_card .btnWrap {
  text-align: left;
}
.bl_importArticle .bl_newsDetail .sectionWrap {
  margin: 80px 0;
}
.bl_importArticle .bl_newsDetail .sectionWrap:first-child {
  margin-top: 0;
}
@media only screen and (max-width: 767px) {
  .bl_importArticle .bl_newsDetail .sectionWrap {
    margin: 60px 0;
  }
}
.bl_importArticle .bl_newsDetail .btnWrap {
  margin-top: 55px;
}
.bl_importArticle .bl_newsDetail .btnWrap + .btnWrap {
  margin-top: 20px;
}
.bl_importArticle .bl_newsDetail .btnWrap.-center {
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .bl_importArticle .bl_newsDetail .btnWrap {
    margin-top: 30px;
  }
}
.bl_importArticle .bl_newsDetail .el_btn .icon-blank {
  margin-left: 8px;
}
.bl_importArticle .bl_newsDetail .el_btn .icon-blank::after {
  font-family: "svgicon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e907";
  font-size: 1.2rem;
  color: #5d5d5d;
  transition: all 0.3s;
}
.bl_importArticle .bl_newsDetail .el_btn:hover .icon-blank::after {
  color: #fff;
}
.bl_importArticle .bl_newsDetail .bl_media {
  align-items: flex-start;
}
.bl_importArticle .bl_newsDetail .bl_media .mediaImg {
  width: calc(50% - 20px);
}
.bl_importArticle .bl_newsDetail .bl_media .mediaImg img {
  width: auto;
  max-width: 100%;
}
.bl_importArticle .bl_newsDetail .bl_media .mediaImg figcaption {
  text-align: center;
  margin-top: 10px;
}
.bl_importArticle .bl_newsDetail .bl_media .mediaBody .bclo-textWrap {
  margin-top: 0;
}
.bl_importArticle .bl_newsDetail .bl_media .mediaBody .bclo-link {
  margin-top: 15px;
}
@media print, screen and (min-width: 768px) {
  .bl_importArticle .bl_newsDetail .bl_media[class*=bclo-rightImage] .mediaImg {
    margin: 0 0 0 40px;
  }
  .bl_importArticle .bl_newsDetail .bl_media.bclo-leftImage-sm .mediaImg, .bl_importArticle .bl_newsDetail .bl_media.bclo-rightImage-sm .mediaImg {
    width: 33%;
  }
  .bl_importArticle .bl_newsDetail .bl_media .mediaImg.-thin {
    width: 33%;
  }
}
@media only screen and (max-width: 767px) {
  .bl_importArticle .bl_newsDetail .bl_media .mediaImg {
    width: 100%;
    margin: 0 0 20px;
  }
  .bl_importArticle .bl_newsDetail .bl_media .mediaImg.-thin {
    width: 100%;
  }
  .bl_importArticle .bl_newsDetail .bl_media + .bl_media {
    margin-top: 40px;
  }
  .bl_importArticle .bl_newsDetail .bl_media[class*=bclo-rightImage] .mediaImg {
    margin-top: 20px;
  }
}
.bl_importArticle .bl_newsDetail .bclo-textWrap {
  margin: 40px 0;
}
.bl_importArticle .bl_newsDetail .bclo-textWrap-title {
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 30px;
}
.bl_importArticle .bl_newsDetail .bclo-textWrap.-thin {
  max-width: 1080px;
  margin: 60px auto;
}
@media only screen and (max-width: 767px) {
  .bl_importArticle .bl_newsDetail .bclo-textWrap {
    margin: 30px 0;
  }
  .bl_importArticle .bl_newsDetail .bclo-textWrap-title {
    font-size: 2rem;
    margin-bottom: 15px;
  }
  .bl_importArticle .bl_newsDetail .bclo-textWrap.-thin {
    max-width: 100%;
    margin: 40px auto;
  }
}
.bl_importArticle .bl_newsDetail .bclo-col01 .cardImg,
.bl_importArticle .bl_newsDetail .bclo-col02 .cardImg {
  text-align: center;
}
.bl_importArticle .bl_newsDetail .bclo-col01 .cardImg img,
.bl_importArticle .bl_newsDetail .bclo-col02 .cardImg img {
  width: auto;
  max-width: 100%;
  text-align: center;
}
.bl_importArticle .bl_newsDetail .bclo-col01 {
  margin: 0 0 80px;
}
@media only screen and (max-width: 767px) {
  .bl_importArticle .bl_newsDetail .bclo-col01 {
    margin: 0 0 60px;
  }
}
@media print, screen and (min-width: 768px) {
  .bl_importArticle .bl_newsDetail .bclo-col03 .bclo-textWrap-title,
  .bl_importArticle .bl_newsDetail .bclo-col04 .bclo-textWrap-title {
    font-size: 2rem;
  }
}
@media print, screen and (min-width: 768px) and (max-width: 959px) {
  .bl_importArticle .bl_newsDetail .bclo-col03 .bclo-textWrap-title,
  .bl_importArticle .bl_newsDetail .bclo-col04 .bclo-textWrap-title {
    font-size: 1.8rem;
  }
}
@media print, screen and (min-width: 768px) and (max-width: 959px) {
  .bl_importArticle .bl_newsDetail .bclo-col03 .bclo-textWrap-text,
  .bl_importArticle .bl_newsDetail .bclo-col03 .bclo-cap,
  .bl_importArticle .bl_newsDetail .bclo-col04 .bclo-textWrap-text,
  .bl_importArticle .bl_newsDetail .bclo-col04 .bclo-cap {
    font-size: 1.4rem;
  }
}
.bl_importArticle .bl_newsDetail .bclo-imgLink {
  display: block;
  transition: 0.25s cubic-bezier(0.17, 0.935, 0.305, 1);
}
.bl_importArticle .bl_newsDetail .bclo-imgLink:focus, .bl_importArticle .bl_newsDetail .bclo-imgLink:hover {
  opacity: 0.75;
  text-decoration: none;
}
.bl_importArticle .bl_newsDetail .bclo-cap {
  margin-top: 15px;
  color: #333;
}
@media only screen and (max-width: 767px) {
  .bl_importArticle .bl_newsDetail .bclo-cap {
    font-size: 1.4rem;
    margin-top: 10px;
  }
}
.bl_importArticle .bl_newsDetail .bclo-title-lg {
  width: 100%;
  margin-bottom: 40px;
  font-size: 3.6rem;
  font-weight: 600;
  color: #333;
  padding-top: 10px;
}
@media only screen and (max-width: 767px) {
  .bl_importArticle .bl_newsDetail .bclo-title-lg {
    padding-top: 20px;
    margin-bottom: 40px;
    font-size: 2.4rem;
  }
}
.bl_importArticle .bl_newsDetail .bclo-title-sm {
  font-size: 2.4rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 30px;
}
@media only screen and (max-width: 767px) {
  .bl_importArticle .bl_newsDetail .bclo-title-sm {
    font-size: 1.8rem;
  }
}
.bl_importArticle .bl_newsDetail .bclo-list {
  margin: 30px 0;
}
@media only screen and (max-width: 767px) {
  .bl_importArticle .bl_newsDetail .bclo-list {
    padding: 20px;
  }
}
.bl_importArticle .bl_newsDetail .bclo-list-li {
  margin-bottom: 5px;
  font-size: 1.6rem;
}
.bl_importArticle .bl_newsDetail .bclo-list-li-a {
  text-decoration: underline;
}
.bl_importArticle .bl_newsDetail .bclo-list.ul-og .mod-bclo-list-li {
  border-bottom: 1px dotted #7d7d7d;
}
.bl_importArticle .bl_newsDetail .bclo-list.ul-disc li {
  margin-left: 1.5em;
  list-style-type: disc;
}
.bl_importArticle .bl_newsDetail .bclo-list.ul-decimal li {
  margin-left: 1.5em;
  list-style-type: decimal;
}
.bl_importArticle .bl_newsDetail .bclo-list.ul-latin li {
  margin-left: 1.5em;
  list-style-type: lower-roman;
}
.bl_importArticle .bl_newsDetail .bclo-list.ul-upperLatin li {
  margin-left: 1.5em;
  list-style-type: upper-roman;
}
.bl_importArticle .bl_newsDetail .bclo-leftImage-lg, .bl_importArticle .bl_newsDetail .bclo-leftImage-sm, .bl_importArticle .bl_newsDetail .bclo-rightImage-lg, .bl_importArticle .bl_newsDetail .bclo-rightImage-sm {
  margin-top: 80px;
}
.bl_importArticle .bl_newsDetail .bclo-free {
  margin-bottom: 40px;
}
.bl_importArticle .bl_newsDetail .bclo-free ol li {
  list-style-position: outside;
  list-style-type: decimal;
  margin-left: 2em;
}
.bl_importArticle .bl_newsDetail .bclo-free ul li {
  list-style-position: outside;
  list-style-type: disc;
  margin-left: 1.6em;
}
.bl_importArticle .bl_newsDetail .bclo-free table:not([border="0"]) {
  border-collapse: collapse;
}
.bl_importArticle .bl_newsDetail .bclo-free table:not([border="0"]) th, .bl_importArticle .bl_newsDetail .bclo-free table:not([border="0"]) td {
  padding: 12px 15px;
}
.bl_importArticle .bl_newsDetail .bclo-free table[border="1"] th, .bl_importArticle .bl_newsDetail .bclo-free table[border="1"] td {
  border: #ccc 1px solid;
}
.bl_importArticle .bl_newsDetail .bclo-free table[border="2"] th, .bl_importArticle .bl_newsDetail .bclo-free table[border="2"] td {
  border: #ccc 2px solid;
}
.bl_importArticle .bl_newsDetail .bclo-free table[border="3"] th, .bl_importArticle .bl_newsDetail .bclo-free table[border="3"] td {
  border: #ccc 3px solid;
}
.bl_importArticle .bl_newsDetail .bclo-free table[border="4"] th, .bl_importArticle .bl_newsDetail .bclo-free table[border="4"] td {
  border: #ccc 4px solid;
}
.bl_importArticle .bl_newsDetail .bclo-free table[border="5"] th, .bl_importArticle .bl_newsDetail .bclo-free table[border="5"] td {
  border: #ccc 5px solid;
}
.bl_importArticle .bl_newsDetail .bclo-free table[border="6"] th, .bl_importArticle .bl_newsDetail .bclo-free table[border="6"] td {
  border: #ccc 6px solid;
}
.bl_importArticle .bl_newsDetail .bclo-free table[border="7"] th, .bl_importArticle .bl_newsDetail .bclo-free table[border="7"] td {
  border: #ccc 7px solid;
}
.bl_importArticle .bl_newsDetail .bclo-free table[border="8"] th, .bl_importArticle .bl_newsDetail .bclo-free table[border="8"] td {
  border: #ccc 8px solid;
}
.bl_importArticle .bl_newsDetail .bclo-free table[border="9"] th, .bl_importArticle .bl_newsDetail .bclo-free table[border="9"] td {
  border: #ccc 9px solid;
}
.bl_importArticle .bl_newsDetail .bclo-free table[border="10"] th, .bl_importArticle .bl_newsDetail .bclo-free table[border="10"] td {
  border: #ccc 10px solid;
}
.bl_importArticle .bl_newsDetail .cf_b_img_types a:hover {
  text-decoration: none;
}
.bl_importArticle .bl_newsDetail .bclo-text {
  margin-bottom: 40px;
}
.bl_importArticle .bl_newsDetail .ta_right {
  text-align: right;
}
.bl_importArticle .bl_newsDetail .ta_center {
  text-align: center;
}
.bl_importArticle .bl_newsDetail .contactbtn {
  margin: 30px auto 0;
  text-align: center;
}
.bl_importArticle .bl_newsDetail .contactbtn a {
  display: inline-block;
  padding: 8px 30px;
  border: #004098 1px solid;
  background: #004098;
  color: #fff;
  transition: all 0.5s cubic-bezier(0.17, 0.935, 0.305, 1);
  text-align: center;
  min-width: 400px;
  transition: all 0.5s;
}
.bl_importArticle .bl_newsDetail .contactbtn a:hover {
  text-decoration: none;
  opacity: 0.75;
}
.bl_importArticle .bl_newsDetail .contactbtn a:hover {
  background: #fff;
  color: #004098;
  opacity: 1;
}
@media only screen and (max-width: 767px) {
  .bl_importArticle .bl_newsDetail .contactbtn a {
    min-width: auto;
    width: auto;
  }
}
.bl_importArticle .bl_newsDetail + .bl_btnWrap {
  margin-top: 30px;
}
.bl_importArticle .bl_importNotesDB {
  /*---------------------------------------
  notes parts
  ----------------------------------------*/
  /* headingBlock01
  ------------------------*/
  /* careerList
  ------------------------*/
  /* tableBlock01
  ------------------------*/
  /* tableBlock01
  ------------------------*/
  /* newsList
  ------------------------*/
  /*.newsList dl{margin:5px 6px 0 14px;}*/
  /* pressList
  ------------------------*/
  /* newslabel
  ------------------------*/
  /* pressList
  ------------------------*/
}
.bl_importArticle .bl_importNotesDB font,
.bl_importArticle .bl_importNotesDB pre {
  font-family: Helvetica, Arial, Tahoma, sans-serif;
  font-weight: 300;
}
.bl_importArticle .bl_importNotesDB .newsDate {
  margin-bottom: 20px;
  text-align: right;
  display: none;
}
.bl_importArticle .bl_importNotesDB .newsInc {
  text-align: right;
  margin-bottom: 4.1666666667vw;
}
.bl_importArticle .bl_importNotesDB .newsTitle {
  margin-bottom: 30px;
  font-weight: bold;
  text-align: center;
  font-size: 114%;
  display: none;
}
.bl_importArticle .bl_importNotesDB .textBlock {
  margin-bottom: 25px;
}
.bl_importArticle .bl_importNotesDB .imgBlock1 {
  margin-bottom: 15px;
  text-align: center;
}
.bl_importArticle .bl_importNotesDB .imgBlock1 .caption {
  margin-top: 5px;
  text-align: center;
}
.bl_importArticle .bl_importNotesDB .imgBlock2 {
  overflow: hidden;
  zoom: 1;
  margin: 0 0 25px -10px;
  width: 1080px;
}
.bl_importArticle .bl_importNotesDB .imgBlock2 .imgBox {
  width: 320px;
  float: left;
  margin-left: 150px;
}
.bl_importArticle .bl_importNotesDB .imgBlock2 .imgBox p,
.bl_importArticle .bl_importNotesDB .imgBlock2 .imgBox img {
  text-align: center;
}
.bl_importArticle .bl_importNotesDB .imgBlock2 .imgBox .caption {
  margin-top: 5px;
}
.bl_importArticle .bl_importNotesDB .btnIndex {
  text-align: center;
}
.bl_importArticle .bl_importNotesDB .textBold {
  font-weight: bold;
}
.bl_importArticle .bl_importNotesDB .inqBtn a,
.bl_importArticle .bl_importNotesDB .btnIndex a {
  display: inline-block;
  padding: 8px 30px 7px;
  background-color: #014099;
  color: #fff;
  font-weight: bold;
}
.bl_importArticle .bl_importNotesDB .inqBtn a:hover,
.bl_importArticle .bl_importNotesDB .btnIndex a:hover {
  text-decoration: none;
  background-color: #00265b;
}
.bl_importArticle .bl_importNotesDB .headingBlock01 {
  font-size: 128%;
  font-weight: bold;
  margin: 20px 0 5px;
  padding: 5px 10px;
  background-color: #eaeaea;
}
.bl_importArticle .bl_importNotesDB .headingBlock02 {
  font-size: 128%;
  font-weight: bold;
  color: #004097;
  margin-bottom: 5px;
}
.bl_importArticle .bl_importNotesDB .headingBlock03 {
  font-size: 114%;
  font-weight: bold;
  margin-bottom: 30px;
}
.bl_importArticle .bl_importNotesDB .headingBlock04 {
  font-size: 114%;
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 6px;
  border-left: 4px solid #004097;
}
.bl_importArticle .bl_importNotesDB .careerList {
  margin: 5px 0 10px;
  padding-top: 10px;
  border-top: #eaeaea 1px solid;
}
.bl_importArticle .bl_importNotesDB .careerList .releaseDate {
  margin-bottom: 5px;
}
.bl_importArticle .bl_importNotesDB .careerList .textBold {
  margin-top: 5px;
}
.bl_importArticle .bl_importNotesDB .tableBlock01 {
  margin-bottom: 15px;
}
.bl_importArticle .bl_importNotesDB .tableBlock01 th,
.bl_importArticle .bl_importNotesDB .tableBlock01 td {
  padding: 5px 5px 5px 10px;
  border-bottom: 5px solid #fff;
}
.bl_importArticle .bl_importNotesDB .tableBlock01 th {
  background-color: #e2e4e7;
  font-weight: normal;
  text-align: left;
}
.bl_importArticle .bl_importNotesDB .tableBlock01 td {
  background-color: #f3f3f3;
}
.bl_importArticle .bl_importNotesDB .newsList a {
  text-decoration: none;
}
.bl_importArticle .bl_importNotesDB .newsList a, .bl_importArticle .bl_importNotesDB .newsList a:link {
  color: #004097;
}
.bl_importArticle .bl_importNotesDB .newsList a:visited {
  color: #4E98FB;
}
.bl_importArticle .bl_importNotesDB .newsList a:hover {
  text-decoration: underline;
}
.bl_importArticle .bl_importNotesDB .newsList dt {
  clear: both;
  float: left;
  padding: 5px 0 0 15px;
  position: relative;
  margin-right: -300px;
  pointer-events: none;
  cursor: pointer;
}
.bl_importArticle .bl_importNotesDB .newsList dt span.date,
.bl_importArticle .bl_importNotesDB .newsList dt span.corpName {
  display: inline-block;
  _display: inline;
  _zoom: 1;
  line-height: 13px;
  padding-right: 11px;
}
.bl_importArticle .bl_importNotesDB .newsList dt img {
  padding-right: 8px;
  vertical-align: middle;
}
.bl_importArticle .bl_importNotesDB .newsList dt a {
  display: block;
}
.bl_importArticle .bl_importNotesDB .newsList dd {
  border-bottom: #FFF 1px solid;
}
.bl_importArticle .bl_importNotesDB .newsList dd a {
  padding: 2.2em 0 10px 15px;
  display: block;
  background-color: #fff;
}
.bl_importArticle .bl_importNotesDB .newsList dd.even a {
  background-color: #fafafa;
}
.bl_importArticle .bl_importNotesDB .newsList dd a:hover, .bl_importArticle .bl_importNotesDB .newsList dd a.hover {
  background-color: #e9f7fb;
}
.bl_importArticle .bl_importNotesDB .pressList dt {
  margin-top: 15px;
}
.bl_importArticle .bl_importNotesDB .pressList dt span {
  margin-right: 10px;
}
.bl_importArticle .bl_importNotesDB .pressList dt img {
  margin-right: 8px;
  vertical-align: middle;
}
@media screen and (max-width: 767px) {
  .bl_importArticle .bl_importNotesDB .newsDate {
    margin-bottom: 10px;
  }
  .bl_importArticle .bl_importNotesDB .newsInc {
    margin-bottom: 8vw;
  }
  .bl_importArticle .bl_importNotesDB .newsTitle {
    margin-bottom: 15px;
  }
  .bl_importArticle .bl_importNotesDB .imgBlock1 img {
    max-width: 100%;
  }
  .bl_importArticle .bl_importNotesDB .imgBlock2 {
    width: 100%;
    margin-left: 0;
  }
  .bl_importArticle .bl_importNotesDB .imgBlock2 .imgBox {
    width: 100%;
    float: none;
    margin: 0 0 15px;
  }
  .bl_importArticle .bl_importNotesDB .newsList dt {
    padding-left: 5px;
    padding-right: 5px;
  }
  .bl_importArticle .bl_importNotesDB .newsList dd a {
    padding-left: 5px;
  }
  .bl_importArticle .bl_importNotesDB .newsList dt img.label {
    width: 66px;
    height: 10px;
  }
  .bl_importArticle .bl_importNotesDB .pressList dt img.label {
    width: 66px;
    height: 10px;
  }
}
.bl_importArticle .bl_importNotesDB *[class*=newslabel] {
  display: inline-block;
  padding: 2px 30px 0;
  margin-left: 10px;
  font-size: 1.2rem;
  line-height: 1.4;
  vertical-align: middle;
}
.bl_importArticle .bl_importNotesDB .newslabelNew {
  background-color: #ed6942;
  color: #fff;
}
.bl_importArticle .bl_importNotesDB .newslabelSeg {
  background-color: #999;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .bl_importArticle .bl_importNotesDB *[class*=newslabel] {
    padding: 2px 10px 0;
  }
}
.bl_importArticle .bl_importNotesDB .contactbtn {
  text-align: center;
  font-size: 1.8rem;
  margin-top: 30px;
  padding: 30px 0 0;
  border-top: 1px solid #666;
}
.bl_importArticle .bl_importNotesDB .contactbtn a,
.bl_importArticle .bl_importNotesDB .back_index a {
  display: block;
  width: 50%;
  padding: 10px;
  color: #FFF;
  text-decoration: none;
  background: #004095;
  margin: 0 auto;
}
.bl_importArticle .bl_importNotesDB .contactbtn a:hover,
.bl_importArticle .bl_importNotesDB .back_index a:hover {
  opacity: 0.8; /* 0.0～1.0 */
  filter: alpha(opacity=80); /* 0～100 */
}
.bl_importArticle .bl_importNotesDB .back_index {
  text-align: center;
  font-size: 1.8rem;
  margin-top: 30px;
}
.bl_importArticle .bl_importNotesDB .back_index a {
  background-color: #fff;
  color: #014099;
  border: 1px solid #014099;
}
.bl_importArticle .bl_importNotesDB .pressListE dt {
  margin-top: 15px;
}
.bl_importArticle .bl_importNotesDB .pressListE dt span {
  margin-right: 10px;
  display: inline-block;
}
.bl_importArticle .bl_importNotesDB .pressListE dt img {
  margin-right: 8px;
  vertical-align: middle;
}
@media screen and (max-width: 767px) {
  .bl_importArticle .bl_importNotesDB .pressListE dt img.label {
    width: 66px;
    height: 10px;
  }
}
.bl_importArticle .bl_importNotesDB .RBlock1 table {
  max-width: 1080px;
}
.bl_importArticle .bl_importNotesDB .RBlock1 font {
  font-size: 1.6rem;
  font-family: inherit;
  -webkit-text-size-adjust: none;
}
.bl_importArticle .bl_importNotesDB .RBlock1 td img {
  height: auto;
}
.bl_importArticle .bl_importNotesDB .RBlock1 table,
.bl_importArticle .bl_importNotesDB .RBlock1 td,
.bl_importArticle .bl_importNotesDB .RBlock1 th {
  border: 1px solid #eeebea;
  padding: 2px;
}
.bl_importArticle .bl_importNotesDB .RBlock2 table {
  max-width: 1080px;
}
.bl_importArticle .bl_importNotesDB .RBlock2 font {
  font-size: 1.6rem;
  -webkit-text-size-adjust: none;
}
.bl_importArticle .bl_importNotesDB .RBlock2 td img {
  height: auto;
}
.bl_importArticle .bl_importNotesDB .RBlock2 table,
.bl_importArticle .bl_importNotesDB .RBlock2 td,
.bl_importArticle .bl_importNotesDB .RBlock2 th {
  border: 0px solid #eeebea;
  padding: 2px;
}
@media only screen and (max-width: 767px) {
  .bl_importArticle .bl_importNotesDB .RBlock1 font {
    font-size: 12px;
  }
  .bl_importArticle .bl_importNotesDB .RBlock1 table th,
  .bl_importArticle .bl_importNotesDB .RBlock1 table td {
    padding: 0.5rem !important;
    line-height: 1.2 !important;
  }
  .bl_importArticle .bl_importNotesDB .RBlock2 font {
    font-size: 12px;
  }
  .bl_importArticle .bl_importNotesDB .RBlock2 table th,
  .bl_importArticle .bl_importNotesDB .RBlock2 table td {
    padding: 0.5rem !important;
    line-height: 1.2 !important;
  }
}
@charset "utf-8";

@use '../../shared/scss/variable'  as *;
@use '../../shared/scss/mixin'  as *;
@use 'news_variable'  as *;
@use "sass:math";


/*-------------------------------------------
list
-------------------------------------------*/
.bl_newsList{

  &:not(.-top){
    margin-top:0;
    min-height: 30vw;
  }

  @include bpSP{
    &:not(.-top){
      min-height: 100vw;
    }
    &[data-spwidth="max"]{
      margin-left: -4%;
      margin-right: -4%;
    }
  }

  .item{
    background-color: #fff;
    + .item{
      margin:0;
      border-top:1px solid #eee;
    }
    &:nth-of-type(2n+1) > a{
      background-color: #fafafa;
    }
  }
  .item > a{
    display: block;
    padding: 10px 12px;
    @include transitionDefault();
    &:hover{
      background-color: #e9f7fb;
      text-decoration: none;
      .title{
        text-decoration: underline;
      }
    }
    @include bpSP{
      padding: 4% 6%;
      .title{
        font-size:1.4rem;
      }
    }
  }
  .tags{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    time{
      display: inline-block;
      color:#333;
      white-space: nowrap;
    }
    span{
      display: inline-block;
      margin-left:10px;
      padding:3px 15px 1px;
      font-size:1.2rem;
      line-height: 1.2;
    }
    .cat,.subcat{
      border-radius: 3px;
      background:#fff;
      white-space: nowrap;
      cursor: pointer;
      &:hover{
        color:palettes(blue,vivid);
        text-decoration: underline;
      }
    }
    .cat{
      color:palettes(blue, vivid);
      border: 1px solid palettes(blue, vivid);
    }
    .subcat{
      color:palettes(black, base);
      border: 1px solid palettes(black, base);
    }

    @include bpSP{
      flex-wrap: wrap;
      margin-bottom: 8px;
      time{
        width:100%;
        font-size:1.4rem;
        margin-bottom: 5px;
      }
      span{
        margin-left:0;
        padding:3px 8px 1px;
        font-size:1.1rem;
        + span{
          margin-left: 5px;
        }
      }
    }
  }
}

.bl_cardUnit.-newslist{
  margin-bottom: 0;
  min-height: 200px;

  .cardImg{
    > a{
      display: block;
      position: relative;
      width:100%;
      padding-top:56.3%;
    }
    img{
      position: absolute;
      top:0;
      bottom:0;
      margin:auto;
    }
  }
  .title{
    line-height: 1.5;
    font-size: clamp(1.4rem, 1.3vw, 1.6rem);
    > a{
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  .tags{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    time{
      display: inline-block;
      color:#333;
      white-space: nowrap;
    }
    span{
      display: flex;
      justify-content: center;
      align-items: center;
      margin:0 10px 5px 0;
      padding:4px 15px 3px;
      font-size:1.2rem;
      line-height: 1.2;
      white-space: nowrap;
      cursor: pointer;
    }
    .cat,.subcat{
      border-radius: 3px;
      background:#fff;
      white-space: nowrap;
      cursor: pointer;
    }
    .cat{
      color:palettes(blue, vivid);
      border: 1px solid palettes(blue, vivid);
      &:hover{
        color:#fff;
        background:palettes(blue, vivid);
      }
    }
    .subcat{
      color:palettes(black, base);
      border: 1px solid palettes(black, base);
      &:hover{
        color:#fff;
        background:#333;
      }
    }

    @include bpSP{
      flex-wrap: wrap;
      time{
        width:100%;
        font-size:1.4rem;
        margin-bottom: 5px;
      }

    }
  }
  &.-noTag{
    .bl_card .tags{
      display: none;
    }
  }
}


/*-------------------------------------------
filter
-------------------------------------------*/
.bl_filterYear{
  display: flex;
  margin-bottom: 40px;
  @include bpSP{
    display: block;
    margin-bottom: 6.5%;
  }
  ul{
    display: flex;
    margin: 0 auto;
    flex-wrap: wrap;
    position: relative;
    padding-left: 5em;
    @include bpSP{
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
      padding-left: calc(1.5em + 4%);
    }
    li{
      margin: 0 10px;
      @include bpSP{
        margin: 0;
        text-align: center;
      }
      &:first-child{
        position: absolute;
        left: 0;
        top: 0;
        @include bpSP{
          margin: 0;
          text-align: left;
        }
      }
    }
  }
  label{
    font-weight: 600;
    font-size: 2.0rem;
    font-size: fontClamp(10,20);
    line-height: 1;
    color: palettes(blue, base);
    border:transparent 1px solid;
    padding:7px 8px 6px;
    transition: all 0.3s;
    text-align: center;

    @include transitionDefault();
    &:hover,&:focus{
      text-decoration: none;
      border-color: palettes(blue, base);
    }
    @include bpSP{
      font-size:1.4rem;
    }
  }
  input{
    display: none;
    &:checked + label{
      color: #fff;
      background: palettes(blue, base);
      border-color: palettes(blue, base);
    }
  }
}

.bl_filterWrap{
  @include bpPC{
    margin-top: -30px;
  }

  .el_detailBtn{
    position: relative;
    display:flex;
    justify-content: center;
    align-items: center;
    margin-bottom:20px;
    opacity: .75;
    @include bpSP{
      margin-bottom:10px;
    }
    button{
      position: relative;
      font-size:1.6rem;
      font-weight: 600;
      color: palettes(black, base);
      background:palettes(blue, light);
      border:none;
      padding: 15px 50px 15px 30px;
      display: flex;
      align-items: center;
      @include transitionDefault;

      &:hover{
        background:#eee;
      }

      &::before{
        content:'';
        display: inline-block;
        width:16px;
        height:16px;
        background: url(../images/icon_equalizer.svg) 0 0 no-repeat;
        background-size: contain;
        margin-right:10px;
      }
      span{
        display: block;
        position: absolute;
        top: 50%;
        transform: translate(0%, -50%);
        right: 20px;
        &::before,&::after{
          content: '';
          display: block;
          width: 16px;
          height: 2px;
          background-color: palettes(black, base);
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        &::after{
          transform: translateY(-50%) rotate(90deg);
          transition: 0.5s;
        }
      }

      @include bpSP{
        font-size:1.4rem;
      }

      &.is_open{
        span::after{
          transform: rotate(0);
          margin-top: -1px;
        }
      }
    }
  }
}

.bl_filterBox{
  margin-bottom:40px;
  background: palettes(blue,light);
  display: none;

  &.is_open{
    display:block;
  }

  @include bpSP{
    margin-right:-4%;
    margin-left:-4%;
  }

  .bl_filterBox_inner{
    padding: 3.3%;
    @include bpSP{
      padding: 8% 5%;
    }
  }

  .bl_filterKeyword{
    @include flexCenter();
    margin-bottom: 40px;
    @include bpSP{
      margin-bottom: 6.5%;
    }
    .el_keyword{
      width: 100%;
      height: 50px;
      max-width: 300px;
      @include bpSP{
        max-width: 100%;
      }
      input[type="text"]{
        width: 100%;
        height: 50px;
        padding: 5px 10px;
        line-height: 50px;
        background: #fff;
        // border: 1px solid #ccc;
        // border-right: none;
        -webkit-appearance: none;
        appearance: none;
      }
      button{
        width: 50px;
        height: 50px;
        background: #fff;
        border: none;
        @include hoverOpacity();
        &::after{
          content: "\e906";
          font-size: 1.6rem;
          color: palettes(black, base);
        }
        &:hover{
          background-color: palettes(black, base);
          border-color:palettes(black, base);
          &::after{
            color:#fff;
          }
        }
      }
    }
  }

  .bl_filterList{
    [role="tablist"]{
      margin-bottom: 2em;
    }
    .listWrap{
      position: relative;
      display: grid;
      margin-top: 2em;

      @include bpPC{
        padding-left: 9em;
        &.-col2{
          grid-template-columns: repeat( 2 , 1fr);
        }
        &.-col3{
          grid-template-columns: repeat( 3 , 1fr)
        }
        &.-col4{
          grid-template-columns: repeat( 4 , 1fr)
        }
      }


      @include bpSP{
        padding-left: 0;
        grid-template-columns: minmax(100px,1fr) 1fr;
      }
      // & + .listWrap{
      //   margin-top: 2em;
      // }
      dt{
        font-weight: 600;
        position: absolute;
        left: 0;
        top: 0;
        line-height: 1;
        white-space: nowrap;
        @include bpSP{
          font-size: 1.4rem;
          // top: 5px;
        }
      }
      dd{
        margin: 0 20px 10px 0;
        line-height: 1.5;
        font-weight: 600;
        @include bpSP{
          &:nth-of-type(2n){
            margin-right:0;
          }
        }
        // .el_check{
        //   font-size: 100%;
        //   @include bpSP{
        //     font-size: 1.2rem ;
        //   }
        //   .control{
        //     // border-color: palettes(blue, base);
        //     @include bpSP{
        //       top: 0;
        //     }
        //   }
        //   &:focus .control,
        //   &:hover .control{
        //     border-color: palettes(blue, vivid);
        //   }
        //   input:focus + .control{
        //     border-color: palettes(blue, vivid);
        //   }
        // }
      }
    }
    .bl_grid{
      padding:0 20px;
      &.-col2, &.-col3{
        margin-bottom:-10px;
      }
      .grid{
        margin-bottom: 10px;
      }

      @include bpPC{
        .grid label{
          font-size:100%;
        }
        &.-fluct{
          .grid + .grid{
            margin-left: 30px;
          }
        }
      }

      @include bpSP{
        padding:0;
      }
    }
  }

  .bl_filterBox_btn{
    @include flexCenter();
    margin-top:30px;
    button{
      @include buttonReset();
      @include transitionDefault();
      padding:14px 30px;
      color:#fff;
      border:1px solid palettes(blue, base);
      font-size:1.4rem;
      &.reset{
        color:palettes(blue, base);
        background:#fff;
        border-color:#fff;
        &:hover{
          border-color:palettes(blue, base);
        }
      }
      &.submit{
        background: palettes(blue, base);
        margin-left:w(20);
        &:hover{
          color:palettes(blue, base);
          background:#fff;
          border-color: palettes(blue, base);
        }
      }

      @include bpSP{
        padding:10px 20px;
      }
    }

    #dataFilterReset{
      .background{
        background-color: palettes(lightblue,type4);
      }
    }
  }
}

.bl_searchNoResult{
  text-align: center;
  font-weight: 600;
}

.bl_moreList{
  text-align: center;
  margin-top:30px;
  .btn {
    @include transitionDefault();
    padding: 10px 30px;
    color: #fff;
    border: 1px solid palettes(blue, base);
    background: palettes(blue, base);
    display: none;
    text-decoration: none;

    &.is_show{
      display: inline-block;
    }
    &:hover {
      color: palettes(blue, base);
      background: #fff;
      border-color: palettes(blue, base);
      text-decoration: none;
    }
  }
}

.bl_backFilter{
  @include flexCenter();
  margin-top: 20px;

  button{
    @include buttonReset();
    position: relative;
    font-size:1.6rem;
    font-weight: 600;
    color: palettes(black, base);
    padding-right: 22px;
    margin-left: 1.5em;
    display: flex;
    align-items: center;
    text-decoration: underline;
    &::after{
      @include arrowMaker(8px, palettes(black, base), top);
      border-width:2px;
      top:60%;
      right: 0;
    }
    &:hover{
      text-decoration: none;
    }
  }
}
